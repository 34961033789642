.adm-dialog {
  --z-index: var(--adm-dialog-z-index, 1000);
  position: fixed;
  z-index: var(--z-index);
}
.adm-dialog .adm-dialog-mask {
  z-index: 0;
}
.adm-dialog-wrap {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 75vw;
  max-width: 500px;
  transform: translate(-50%, -50%);
}
.adm-dialog-main {
  width: 100%;
  font-size: 14px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.adm-dialog-main .adm-dialog-body {
  padding: 20px 12px;
}
.adm-dialog-main .adm-dialog-body-header-wrapper {
  display: flex;
  justify-content: center;
}
.adm-dialog-main .adm-dialog-body-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.adm-dialog-main .adm-dialog-body-content {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 15px;
  line-height: 1.4;
  color: #333;
}
.adm-dialog-main .adm-dialog-footer {
  user-select: none;
}
.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row {
  display: flex;
  align-items: stretch;
  border-top: 0.5px solid var(--adm-border-color);
}
.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row > * {
  flex: 1;
}
.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button {
  padding: 10px;
  font-size: 18px;
  line-height: 25px;
  border-radius: 0;
  border-right: solid 0.5px var(--adm-border-color);
}
.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button-bold {
  font-weight: bold;
}
.adm-dialog-main .adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button:last-child {
  border-right: none;
}
